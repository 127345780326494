/** @jsx jsx */
import { jsx } from "@emotion/react";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import { mq, md } from "../components/styles";

const Template = ({
  data: {
    markdownRemark: { frontmatter, html },
  },
  location,
}) => (
  <Layout location={location}>
    <Helmet>
      <title>{frontmatter.title}</title>
    </Helmet>
    <div
      css={{
        flex: 1,
        padding: "1rem",
        [mq[1]]: {
          padding: "1rem 11vw",
        },
      }}
    >
      <div css={md} dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  </Layout>
);

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;

export default Template;
